import { risico, vermijden, veilig, onbekend } from "./utils";

export function Result({
  selectedMeds,
  land,
}: {
  selectedMeds: string[];
  land: string;
}) {
  const onbekendLijst = onbekend(selectedMeds, land);

  const risicoLijst = risico(selectedMeds, land);
  const vermijdenLijst = vermijden(selectedMeds, land);
  const veiligLijst = veilig(selectedMeds, land);

  return (
    <div className="info_block">
      {veiligLijst.length > 0 && (
        <>
          <div className="c-med-row c-med-row--safe">
            <h3>Veilig te gebruiken de volgende betalactams</h3>
            <span className="c-medicijn-introductie">
              Geen kruisreactie te verwachten obv gelijkenis zijketens of
              ringstructuren
            </span>
            <ul>
              {veiligLijst.map((med) => {
                return <li key={`veilig-${med}`}>{med}</li>;
              })}
            </ul>
          </div>
        </>
      )}

      {risicoLijst.length > 0 && (
        <>
          <div className="c-med-row c-med-row--potentially">
            <h3>Mogelijk risico met de volgende betalactams</h3>
            <span className="c-medicijn-introductie">
              Kruisreactie mogelijk obv gelijkenis zijketens, ringstructuren of
              PPL vorming
            </span>
            <ul>
              {risicoLijst.map((med) => {
                return <li key={`risico-${med}`}>{med}</li>;
              })}
            </ul>
          </div>
        </>
      )}

      {vermijdenLijst.length > 0 && (
        <>
          <div className="c-med-row c-med-row--risk">
            <h3>Vermijd de volgende betalactams</h3>
            <span className="c-medicijn-introductie">
              Vrijwel zeker kruisreactie obv identieke zijketens
            </span>
            <ul>
              {vermijdenLijst.map((med) => {
                return <li key={`vermijden-${med}`}>{med}</li>;
              })}
            </ul>
          </div>
        </>
      )}

      {onbekendLijst.length > 0 && (
        <div className="c-med-row c-med-row--unknown">
          <h3>Geen informatie in de literatuur over de volgende betalactams</h3>
          <span className="c-medicijn-introductie"></span>
          <ul>
            {onbekendLijst.map((med) => {
              return <li key={`onbekend-${med}`}>{med}</li>;
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
