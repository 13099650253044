import { useState } from "react";
import classNames from "classnames";
import uniq from "lodash/uniq";
import medicijnen from "./data/medicijnen.json";
import { Result } from "./Results";
import { ALLE_LANDEN } from "./data/constants";

const alle_medicijnen = medicijnen.map((m) => m.naam).sort();
const landen = [ALLE_LANDEN].concat(
  uniq(medicijnen.map((m) => m.beschikbaar).flat()).filter(Boolean) as string[]
);

export function Checkboxes() {
  const [geselecteerdeMedicijnen, setGeselecteerdeMedicijnen] = useState<
    string[]
  >([]);
  const [geselecteerdLand, setGeselecteerdLand] = useState<string>(ALLE_LANDEN);

  return (
    <>
      <div className="c-choose-block">
        <h2>Alternatieve Betalactam app</h2>
        <p>
          Vink de specifieke betalactams aan waarvoor patiënt een allergie
          heeft
        </p>
        <div className="checkbox-wrapper">
          <ul className="c-checkbox-overview">
            {alle_medicijnen.map((med) => {
              const selected = geselecteerdeMedicijnen.includes(med);
              return (
                <li key={`med-${med}`}>
                  <label
                    htmlFor={`input-${med}`}
                    className={classNames(
                      "checkbox",
                      selected && "checkbox-checked"
                    )}
                  >
                    <input
                      type="checkbox"
                      id={`input-${med}`}
                      value={med}
                      checked={selected}
                      onChange={() => {
                        setGeselecteerdeMedicijnen(
                          selected
                            ? geselecteerdeMedicijnen.filter((m) => m !== med)
                            : [...geselecteerdeMedicijnen, med]
                        );
                      }}
                    />
                    {med}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="c-choose-block">
          <p>Toon alleen middelen verkrijgbaar in</p>
          <ul className="c-checkbox-overview">
            {landen.map((land) => {
              const selected = land === geselecteerdLand;
              return (
                <li key={`land-${land}`}>
                  <label
                    htmlFor={`input-${land}`}
                    className={classNames(
                      "checkbox",
                      selected && "checkbox-checked"
                    )}
                  >
                    <input
                      type="radio"
                      id={`input-${land}`}
                      value={land}
                      checked={selected}
                      onChange={() => {
                        setGeselecteerdLand(land);
                      }}
                    />
                    {land === ALLE_LANDEN ? "Alle landen" : land}
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="c-popup-checkbox">
          <p>TIP: Scroll naar beneden voor het resultaat</p>
        </div>
      </div>
      <Result selectedMeds={geselecteerdeMedicijnen} land={geselecteerdLand} />
    </>
  );
}
