import Popup from "reactjs-popup";
import Cookies from "js-cookie";
import { Checkboxes } from "./Checkboxes";
import { COOKIE_NAME } from "./data/constants";
import classNames from "classnames";
import { useState } from "react";

export const Home = () => {
  // Read from cookie to check if popup needs to be open
  const cookie = Cookies.get(COOKIE_NAME);
  const [selected, setSelected] = useState(false);
  const [open, setOpen] = useState(!cookie);
  const [showError, setShowError] = useState(false);

  return (
    <>
      <Popup
        position="right center"
        closeOnDocumentClick={false}
        closeOnEscape={false}
        open={open}
      >
        <div className="c-modal">
          <p>
            Door middel van deze app kunt u een alternatief betalactam
            antibioticum kiezen voor een patiënt met een allergie voor één of
            meerdere betalactamantibiotica. U mag dit alleen doen als het gaat
            om een type I of een milde type IV allergie. Als sprake is van een
            type II, type III of ernstige type IV allergie, dan dient u advies
            in te winnen bij uw allergie expert. Bent u niet zeker van het type
            reactie dan wel de ernst van de reactie, doorloop dan eerst de
            risico app op{" "}
            <a
              href="http://farmacopedia.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              farmacopedia.nl
            </a>
            .
          </p>
          <p>
            Deze app is gemaakt voor onderwijs en onderzoeksdoeleinden. De
            gebruikte bronnen zijn de tabellen uit de artikelen van Romano
            (link), Zagursky (link), Trubiano (link) en de SWAB richtlijn
            (link). Hoewel bij het maken uiterste zorgvuldigheid is betracht,
            kunnen de makers van de app geen garantie geven dat er niet toch een
            fout in is geslopen. U blijft dan ook te allen tijde zelf
            verantwoordelijk voor (de gevolgen van) het toedienen van het
            alternatief en eventuele reacties. Hierbij dient u zich ook te
            realiseren dat een patient uiteraard ook een nieuwe allergische
            reactie kan ontwikkelen zonder dat sprake is van een kruisreactie
            met andere betalactams. Evenmin kunnen bijwerkingen kunnen worden
            uitgesloten.
          </p>
          {showError && <p>Bevestig dat u bovenstaande tekst heeft gelezen.</p>}
          <label
            htmlFor={`input-approve`}
            className={classNames("checkbox", selected && "checkbox-checked")}
          >
            <input
              type="checkbox"
              id={`input-approve`}
              value="approve"
              checked={selected}
              onChange={() => {
                setSelected(!selected);
              }}
            />
            Ik heb bovenstaande tekst gelezen en ik verklaar me akkoord
          </label>
          <button
            onClick={() => {
              if (selected) {
                setShowError(false);
                Cookies.set(COOKIE_NAME, "approve", { expires: 365 });
                setOpen(false);
              } else {
                setShowError(true);
              }
            }}
          >
            Doorgaan
          </button>
        </div>
      </Popup>

      <Checkboxes />
    </>
  );
};
