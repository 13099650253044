import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Home } from "./Home";
import "./style.scss";
import "reactjs-popup/dist/index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const routes = [
  {
    path: "/",
    element: <Home />,
  },
];

const router = createBrowserRouter(routes);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
