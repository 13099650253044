import uniq from "lodash/uniq";
import medicijnen from "./data/medicijnen.json";
import { ALLE_LANDEN } from "./data/constants";

export function risico(geneesmiddelen: string[], land: string) {
  if (geneesmiddelen.length === 0) {
    return [];
  }

  const onbekendLijst = onbekend(geneesmiddelen, land);
  const vermijdenLijst = vermijden(geneesmiddelen, land);

  return uniq(
    medicijnen
      .filter((med) => geneesmiddelen.includes(med.naam))
      .map((med) => med.risico)
      .flat()
      .filter(
        (med) =>
          !(vermijdenLijst.includes(med!) || onbekendLijst.includes(med!)) &&
          (land === ALLE_LANDEN ||
            medicijnen.find((m) => m.naam === med)?.beschikbaar?.includes(land))
      )
      .sort()
  ) as string[];
}

export function vermijden(geneesmiddelen: string[], land: string) {
  if (geneesmiddelen.length === 0) {
    return [];
  }

  const onbekendLijst = onbekend(geneesmiddelen, land);

  return uniq(
    medicijnen
      .filter((med) => geneesmiddelen.includes(med.naam))
      .map((med) => med.vermijden)
      .flat()
      .filter(
        (med) =>
          !onbekendLijst.includes(med!) &&
          (land === ALLE_LANDEN ||
            medicijnen.find((m) => m.naam === med)?.beschikbaar?.includes(land))
      )
      .sort()
  );
}

export function veilig(geneesmiddelen: string[], land: string) {
  if (geneesmiddelen.length === 0) {
    return [];
  }

  const onbekendLijst = onbekend(geneesmiddelen, land);
  const risicoLijst = risico(geneesmiddelen, land);
  const vermijdenEnrisicoLijst = vermijden(geneesmiddelen, land).concat(
    risicoLijst
  );

  return uniq(
    medicijnen
      .filter((med) => geneesmiddelen.includes(med.naam))
      .map((med) => med.veilig)
      .flat()
      .filter(
        (med) =>
          !(
            vermijdenEnrisicoLijst.includes(med!) ||
            onbekendLijst.includes(med!)
          ) &&
          (land === ALLE_LANDEN ||
            medicijnen.find((m) => m.naam === med)?.beschikbaar?.includes(land))
      )
      .sort()
  );
}

export function onbekend(geneesmiddelen: string[], land: string) {
  if (geneesmiddelen.length === 0) {
    return [];
  }

  return uniq(
    geneesmiddelen
      .map((middel) => {
        const medicijn = medicijnen.find((m) => m.naam === middel);
        if (!medicijn) {
          return [];
        }

        const onbekend = medicijnen
          .filter(
            (m) =>
              !(
                medicijn.risico?.includes(m.naam) ||
                medicijn.veilig.includes(m.naam) ||
                medicijn.vermijden.includes(m.naam)
              ) &&
              (land === ALLE_LANDEN || m.beschikbaar?.includes(land))
          )
          .map((m) => m.naam);

        return onbekend;
      })
      .flat()
  );
}
